/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import SearchResults from '~/components/Pages/AskAugust/SearchResults';

const AskAugustSearchResultsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: contentfulPage(slug: { eq: "ask-august" }) {
        ...ContentPageFragment
      }
      askAugustQuestions: allContentfulAskAugustQuestion {
        nodes {
          ...AskAugustQuestionFragment
        }
      }
    }
  `);
  const {
    askAugustQuestions: { nodes: questionNodes },
    page,
  } = data;

  return (
    <Layout>
      <Metadata title="Ask August" />
      <SearchResults
        page={page}
        questions={questionNodes.filter((item) => !item.question.includes('Unification Test'))}
      />
    </Layout>
  );
};

export default AskAugustSearchResultsPage;
