/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import { useEffect, useState } from 'react';
import { Flex, Heading, jsx } from 'theme-ui';
import _ from 'lodash';
import AskAugustList from '~/components/AskAugust/List';
import { useSearch } from '~/contexts/SearchContext';
import Spacer from '~/components/Generic/Layout/Spacer';
import LiveSearch from '~/components/AskAugust/LiveSearch';
import AskAugustLayout from './Layout';
import useDebounce from '~/hooks/components/use-debounce';

const SearchResults = ({ page, questions }) => {
  const { search, setIsSearching } = useSearch();
  const [parsedResults, setParsedResults] = useState([]);
  const searchQuery = useDebounce(search, 600);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('q', searchQuery);

    setIsSearching(true);
    fetch(`/.netlify/functions/search?${searchParams.toString()}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        const populated = data
          .map((i) => questions.find((q) => q.slug === i.fields.slug))
          .filter(Boolean);

        setParsedResults(populated);

        if (!window.analytics) return;

        window.analytics.track('Ask August Search', { query: searchQuery });
      })
      .finally(() => setIsSearching(false));
  }, [searchQuery]);

  const { sections } = page;

  const meetTheStaff = sections[1];

  return (
    <AskAugustLayout meetTheStaff={meetTheStaff}>
      <Spacer height="40px" />
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Flex sx={{ justifyContent: 'center' }}>
          <Heading as="h2" variant="h2">
            Search Results
          </Heading>
        </Flex>
        <Spacer height="40px" />
        <LiveSearch />
      </Flex>
      <Spacer height="40px" />
      {_.size(parsedResults) > 0 ? (
        <AskAugustList listItems={parsedResults} />
      ) : (
        <AskAugustList listItems={questions} />
      )}
    </AskAugustLayout>
  );
};

export default SearchResults;
