/** @jsx jsx */
import { Flex, Input, Spinner, Text, jsx } from 'theme-ui';
import { useSearch } from '~/contexts/SearchContext';

const LiveSearch = () => {
  const { setSearch, isSearching } = useSearch();

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        transform: ['scale(0.67)', 'scale(0.75)', 'none'],
        width: ['150%', null, 'auto'],
        maxWidth: ['70rem', null, 'none'],
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          height: '7.5rem',
          width: ['100%', null, '55rem'],
          border: '1px solid #e3232a',
          borderRadius: '19px',
        }}
      >
        <Input
          name="search"
          ariaLabel="question search"
          placeholder="Search for anything e.g. pms, pain, mental health, etc..."
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            border: 'none',
            borderRadius: '19px',
            color: 'alizarinCrimson',
            pl: '2.7rem',
            fontSize: [4, 6, 8],
            '&::placeholder': {
              color: 'alizarinCrimson',
              opacity: 0.7,
              fontSize: [4, 6, 8],
            },
          }}
        />

        {isSearching && (
          <Spinner
            color="alizarinCrimson"
            strokeWidth={2}
            size={24}
            sx={{ position: 'absolute', right: '2rem', top: '50%', transform: 'translateY(-50%)' }}
          />
        )}
      </Flex>
      <Text sx={{ color: 'grey', fontSize: [3, 4, 5] }}>
        Hint: start typing, and results will instantly be filtered
      </Text>
    </Flex>
  );
};

export default LiveSearch;
